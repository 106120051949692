/* @import '~antd/dist/antd.dark.css'; */

* {
  font-family: 'Source Sans Pro', sans-serif;
}

[data-theme='dark'] {
  --color-text: #fff;
}

.logo {
  min-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.status-danger,
.status-error {
  color: red;
}

.panel-danger,
.panel-error {
  color: #e47373;
  display: inline-block;
}

.panel-success {
  color: var(--color-text);
  display: inline-block;
}

.panel-warning {
  color: #d3bb36;
  display: inline-block;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-title {
  font-size: 14px;
  font-weight: bold;
}

.site-page-header {
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 10px;
}
.mobile-menu {
  display: none;
}

.ant-spin-dot-item {
  background-color: #999;
}
.ant-spin-text {
  color: #eee;
}

.small-label {
  font-size: 16px;
  margin-left: 4px;
}

.collapse-title {
  font-weight: bold;
  font-size: 14px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 4px 12px;
  display: flex;
  align-items: center;
}

.step-show {
  opacity: 1;
  height: 100%;
}

.step-hide {
  opacity: 0;
  height: 1px;
}
.step-scroll {
  height: 90vh;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 6px;
  height: 12px;
  width: 12px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.red {
  background: rgb(180, 0, 0);
  box-shadow: 0 0 0 0 rgba(180, 0, 0, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(180, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(180, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(180, 0, 0, 0);
  }
}

.ant-input-number.size-sm {
  width: 60px !important;
}

.with-interval {
  display: inline-flex;
  margin-left: 40px;
}

.interval-label {
  color: #888;
  font-size: 12px;
  margin-right: 8px;
}

.interval-value {
  margin-right: 40px;
}

.date-divider {
  margin: 0 10px 0 0px;
}

.category-select-wrapper {
  float: right;
  z-index: 90;
  top: 7px;
  right: 100px;
  position: relative;
  padding: 2px 30px;
  border: 1px solid #333;
  border-radius: 4px;
}

@media screen and (max-width: 736px) {
  .ant-layout-sider {
    left: -100px !important;
    transition: 0.5s ease-in;
    z-index: 101;
  }

  .mobile-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
    height: 64px;
  }

  .ant-layout-header {
    left: 0px !important;
  }
  .ant-layout-content {
    margin: 84px 16px 0px 0px !important;
  }

  .ant-layout-sider.sider-show {
    left: 0px !important;
    transition: 0.5s ease-out;
  }
}

@media screen and (max-width: 1500px) {
  .category-select-wrapper {
    right: 24px !important;
  }
}

.pingerror .ant-modal-content {
  border: 8px solid rgb(190, 28, 36) !important;
}

.subpar {
  font-size: 11px;
  color: #888;
  width: 100%;
  text-align: right;
}
